import React from 'react'
import { useLocation } from 'react-router-dom';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { updateRole } from '../features/updateRoleSlice';
import { Card, Container, Form, Col, Button, Row, Alert, Spinner } from 'react-bootstrap';
import { resetUpdateRoleError } from '../features/updateRoleSlice';
import { resetUpdateRole } from '../features/updateRoleSlice';

import { FaPlus, FaMinus } from 'react-icons/fa';




function UpdateRole() {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const aws_role_arn = queryParams.get('arn')
    const aws_account_number = queryParams.get('account_number')
    const aws_account_tag = queryParams.get('account_tag')
    const aws_role_name = queryParams.get('role_name') 
    const session_timeout = queryParams.get('session_timeout')

    const { instance, inProgress, accounts } = useMsal();

    const { loading, isSuccess, error, errorMessage } = useSelector((state) => state.updateRole)
    const getRoleData = useSelector((state) => state.getRole)

    const [emails, setEmails] = useState( getRoleData.role.admin_emails )
    const [emailError, setEmailError] = useState('');

    function handleEmailChange(index, event) {
        const newEmail = event.target.value;
        const newEmails = emails.map((email, i) => (i === index ? newEmail : email));
        setEmails(newEmails);
        setEmailError('');
    }

    const handleAddEmail = () => {
        if (emails.includes('')) {
            setEmailError('Please fill in the existing email field before adding a new one.');
            return;
          }
          setEmails([...emails, '']);
      };

    const handleRemoveEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
        setEmailError('');
      };

    const [formData, setFormData] = useState(
        {
            aws_role_arn: aws_role_arn,
            aws_role_name: aws_role_name,
            aws_account_number: aws_account_number,
            aws_account_tag: aws_account_tag,
            session_timeout: session_timeout
        }
      )
    
    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    const [validated, setValidated] = useState(false);

    const dispatch = useDispatch()

    // dispatch(resetUpdateRoleError())
    // dispatch(resetUpdateRole())

      const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }

        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
            };

        if (inProgress === InteractionStatus.None && form.checkValidity()) {
           instance
               .acquireTokenSilent(accessTokenRequest)
               .then((accessTokenResponse) => {
                   let accessToken = accessTokenResponse.accessToken;
                   const data = {
                        'access_token': `Bearer ${accessToken}`,
                        'email': instance.getActiveAccount().username,
                        'aws_role_arn': formData.aws_role_arn,
                        'aws_role_name': formData.aws_role_name,
                        'aws_account_number': formData.aws_account_number,
                        'aws_account_tag': formData.aws_account_tag,
                        'session_timeout': formData.session_timeout,
                        'admin_emails': emails,
                   }
                   dispatch(updateRole(data))
                       .catch((error) => {
                           if (error instanceof InteractionRequiredAuthError) {
                               instance.acquireTokenRedirect(accessTokenRequest);
                           }
                       })
               })         
       }
      }

  return (
    <>
        <h3>AWS Role Update</h3>
        <hr></hr>
        {emailError && <Alert variant="danger" dismissible >{emailError}  </Alert>}
        { error && <Alert variant='danger' onClose={() => dispatch(resetUpdateRoleError())} dismissible>{errorMessage}</Alert>}
        { isSuccess && <Alert variant='success' dismissible onClose={() => dispatch(resetUpdateRole())}>Role Updated Successfully!</Alert>}
        { loading && <Spinner animation="border" /> }
        <Card className="card border-dark mb-3">
            <Container className="card-header">
                Update Role
            </Container>
            <Container className="card-body">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>AWS Role ARN:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="AWS Role ARN"
                                value={formData.aws_role_arn}
                                onChange={handleChange}
                                name="aws_role_arn"
                                disabled
                                
                            />
                            <Form.Text className="text-mutted" style={{ color: 'green' }}>
                            The role arn can't be updated. If needed, delete the existing role and create a new one.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">Please Provide Account Tag Name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Role Name:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Role Name"
                                value={formData.aws_role_name}
                                onChange={handleChange}
                                name="aws_role_name"
                            />
                            <Form.Control.Feedback type="invalid">Please Provide a valid role Name</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>AWS Account Number:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="AWS Account Number"
                                value={formData.aws_account_number}
                                onChange={handleChange}
                                name="aws_account_number"
                                disabled
                            />
                            <Form.Text className="text-mutted" style={{ color: 'green' }}>
                            The account number can't be updated. If needed, delete the existing role and create a new one.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">Please Provide Account Number.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Account Tag:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="AWS Account Tag"
                                value={formData.aws_account_tag}
                                onChange={handleChange}
                                name="aws_account_tag"
                            />
                            <Form.Control.Feedback type="invalid">Please Provide AWS Account Tag.</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                    <Form.Group as={Col}>
                    <Form.Label>Sessions Timeout Value(in Hours):</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Admin Email/s:</Form.Label>
                    </Form.Group>
                    </Row>
                    <Row>
                    <Form.Group as={Row} >
                        <Col sm={6}>
                            <Form.Select 
                            aria-label="Default select example"
                            id="role"
                            name="session_timeout"
                            value={formData.session_timeout}
                            onChange={handleChange}
                            required
                            >
                                <option label='Select an Time Out Value ...'></option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                                <option value='11'>11</option>
                                <option value='12'>12</option>

                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Select a session timeout Value
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={6}>
                            {emails !== undefined && emails.map((email, index) => (
                            <Form.Group as={Row} key={index} controlId={`formEmail${index}`} style={{ marginBottom: '10px' }}>
                            <Col sm={8}>
                                <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => handleEmailChange(index, e)}
                                placeholder={`Admin Email ${index + 1}`}
                                required
                                />
                            </Col>
                            <Col sm={1}>
                                {emails.length > 1 && (
                                <Button variant="danger" type="button" onClick={() => handleRemoveEmail(index)}>
                                    <FaMinus style={{ fontSize: '10px' }}/>
                                </Button>
                                )}
                            </Col>

                            <Col sm={1}>

                                { index === emails.length - 1 && <Button style={{ marginLeft: '10px'}} variant="success" type="button" onClick={handleAddEmail}>
                                <FaPlus style={{ fontSize: '10px' }}/>
                                </Button> }

                            </Col>
                        </Form.Group>
                    ))} 
                    {/* </Col> */}
                    </Col>
                    </Form.Group>
                    </Row>  
                    <Button variant='warning' type="submit" disabled = {loading}>Update Role</Button>
                </Form>
            </Container>
        </Card>
    </>
  )
}

export default UpdateRole