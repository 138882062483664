import React from 'react'
import { ListGroup, Container, Collapse } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { FaChevronDown, FaChevronUp, FaUserFriends, FaUser, FaAws, FaWrench, FaLock, FaUserPlus, FaUserMinus, FaCogs} from 'react-icons/fa'

import { getUserData } from '../features/userSlice'

function SideBar() {
    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();

    const { loginUser, isLoaded, error} = useSelector((state) => state.user)

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;

            console.log(accessToken)
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'user_name': instance.getActiveAccount().name,
            }
            dispatch(getUserData(data))
            //dispatch(getPendingRequests(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }, [instance, accounts, inProgress, dispatch]);

    const [openManageRole, setOpenManageRole] = useState(false);

    const [openManageUser, setOpenManageUser] = useState(false);

    const [openManageRoleAccess, setOpenManageRoleAccess] = useState(false);

  return (
    <Container>
    <ListGroup className='nav-item'>
        <LinkContainer to="/">
            <ListGroup.Item action className="list-group-item-info nav-link">
                Home
            </ListGroup.Item>
        </LinkContainer>
        <LinkContainer to="/user/my/roles">
            <ListGroup.Item action className="list-group-item-info nav-link">
                Access AWS Role
                <FaAws style={{ marginLeft: '10px', fontSize: '24px'}}/>
            </ListGroup.Item>
        </LinkContainer>

        { !error && isLoaded && loginUser.role === 'Super Admin' && (
            <>
                <ListGroup.Item action className="list-group-item-info nav-link" onClick={() => setOpenManageRole(!openManageRole)} aria-controls="super-admin-collapse" aria-expanded={openManageRole}>
                    Manage Role &#160;
                    <FaCogs style={{ marginLeft: '10px', fontSize: '22px'}}/>
                    {
                        openManageRole ? <FaChevronUp style={{ float: 'right' }}></FaChevronUp> : <FaChevronDown style={{ float: 'right' }}></FaChevronDown>
                    }
                </ListGroup.Item>
                <Collapse in={openManageRole}>
                    <div id="super-admin-collapse">
                        <LinkContainer to="/admin/new/role">
                            <ListGroup.Item action className="list-group-item-secondary nav-link">
                            <FaAws style={{ fontSize: '20px'}} /> &#160; Add Role
                            </ListGroup.Item>
                        </LinkContainer>
                        <LinkContainer to="/admin/roles">
                            <ListGroup.Item action className="list-group-item-secondary nav-link">
                            <FaWrench />&#160; &#160; Manage Existing Roles
                            </ListGroup.Item>
                        </LinkContainer>
                    </div>
                </Collapse>
            </>
        )}
            
            { !error && isLoaded && (loginUser.role === 'Super Admin') && (
                <>
                    <ListGroup.Item action className="list-group-item-info nav-link" onClick={() => setOpenManageUser(!openManageUser)} aria-controls="super-admin-collapse" aria-expanded={openManageUser}>
                        Manage User &#160;
                        <FaUser style={{ marginLeft: '10px'}}/>
                        {
                        openManageUser ? <FaChevronUp style={{ float: 'right' }}></FaChevronUp> : <FaChevronDown style={{ float: 'right' }}></FaChevronDown>
                        }
                    </ListGroup.Item>
                    <Collapse in={openManageUser}>
                        <div id="super-admin-collapse">
                            <LinkContainer to="/admin/new/user">
                                <ListGroup.Item action className="list-group-item-secondary nav-link">
                                <FaUserPlus />&#160; &#160; Add User
                                </ListGroup.Item>
                            </LinkContainer>
                            <LinkContainer to="/admin/users">
                                <ListGroup.Item action className="list-group-item-secondary nav-link">
                                <FaUserFriends />&#160; &#160; Manage Existing Users
                                </ListGroup.Item>
                            </LinkContainer>
                        </div>
                    </Collapse>
                </>
            )}
        { !error && isLoaded && (loginUser.role === 'Super Admin' || loginUser.role === 'Admin') && (
            <>
            <ListGroup.Item action className="list-group-item-info nav-link" onClick={() => setOpenManageRoleAccess(!openManageRoleAccess)} aria-controls="super-admin-collapse" aria-expanded={openManageRoleAccess}>
                Manage User Access &#160;&#160;&#160;
                <FaLock />
                {
                openManageRoleAccess ? <FaChevronUp style={{ float: 'right' }}></FaChevronUp> : <FaChevronDown style={{ float: 'right' }}></FaChevronDown>
                }
            </ListGroup.Item>
            <Collapse in={openManageRoleAccess}>
                <div id="super-admin-collapse">
                    <LinkContainer to="/admin/user/access/add">
                        <ListGroup.Item action className="list-group-item-secondary nav-link">
                        <FaUserPlus />&#160; &#160; Add User Access
                        </ListGroup.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/user/access/remove">
                        <ListGroup.Item action className="list-group-item-secondary nav-link">
                        <FaUserMinus />&#160; &#160; Remove User Access
                        </ListGroup.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/roles">
                        <ListGroup.Item action className="list-group-item-secondary nav-link">
                        <FaWrench />&#160; &#160; My Admin Roles
                        </ListGroup.Item>
                    </LinkContainer>
                </div>
            </Collapse>
        </>
        )}
    </ListGroup>
</Container>
  )
}

export default SideBar
