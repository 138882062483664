import React from 'react'
import { Col } from 'react-bootstrap'

function Footer() {
  return (
    <footer className='fixed-bottom'>
                <Col className="text-center py-3 mt-auto bg-light">Copyright &copy; 2024 Siemens Energy</Col>
    </footer>
  )
}

export default Footer
