import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useIsAuthenticated } from '@azure/msal-react';
import ErrorPage from './pages/ErrorPage';


import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import LogOut from './pages/LogOut'
import CreateRole from './components/CreateRole';
import ManageAdmins from './pages/ManageAdmins';
import AWSRole from './components/AWSRole';
import UpdateRole from './components/UpdateRole';
import ListAllRoles from './components/ListAllRoles';
import AddUser from './components/AddUser';
import ListAllUsers from './components/ListAllUsers';
import User from './components/User';
import UpdateUser from './components/UpdateUser';
import AddUserAccess from './components/AddUserAccess';
import RemoveUserAccess from './components/RemoveUserAccess';
import ListUserRolePage from './pages/ListUserRolePage';
import GetCredentials from './components/GetCredentials';


function AppRoutes() {
  const isAuthenticated = useIsAuthenticated()
  
  return (
    <main className='my-2'>
      <Container>
        <Routes>
            <Route path='/' element={isAuthenticated ? <HomePage /> : <LoginPage />}/>
            <Route path='/login' element={<LoginPage />}/>
            <Route path='/logout' element={<LogOut/>}/>
            <Route path='/super-admin/users' element={isAuthenticated ? <ManageAdmins/>: <LoginPage />}/>
            <Route path='/admin/new/role' element={isAuthenticated ? <CreateRole/>: <LoginPage />}/>
            <Route path='/admin/roles' element={isAuthenticated ? <ListAllRoles/>: <LoginPage />}/>
            <Route path='/admin/role' element={isAuthenticated ? <AWSRole/>: <LoginPage />}/>
            <Route path='/admin/role/update' element={isAuthenticated ? <UpdateRole/>: <LoginPage />}/>
            <Route path='/admin/new/user' element={isAuthenticated ? <AddUser/>: <LoginPage />}/>
            <Route path='/admin/users' element={isAuthenticated ? <ListAllUsers/>: <LoginPage />}/>
            <Route path='/admin/user' element={isAuthenticated ? <User/>: <LoginPage />}/>
            <Route path='/admin/user/update' element={isAuthenticated ? <UpdateUser/>: <LoginPage />}/>
            <Route path='/admin/user/access/add' element={isAuthenticated ? <AddUserAccess/>: <LoginPage />}/>
            <Route path='/admin/user/access/remove' element={isAuthenticated ? <RemoveUserAccess/>: <LoginPage />}/>
            <Route path='/user/my/roles' element={isAuthenticated ? <ListUserRolePage/>: <LoginPage />}/>
            <Route path='/user/credentials' element={isAuthenticated ? <GetCredentials/>: <LoginPage />}/>
            <Route path='*' element={<ErrorPage />} /> 
        </Routes>
      </Container>
    </main>
  )
}

export default AppRoutes