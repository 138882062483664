import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    roles: [],
    role_count: 0,
    loading: false,
    isloaded: false,
    error: false,
    errorMessage: null
}

export const getAllRoles = createAsyncThunk(
        'getAllRoles',
        async (data, {rejectWithValue}) => {
        const headers = {
            'access_token': data.access_token,
            'email': data.email,
            }

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/admin/roles`, { headers })
            if (response.status === 200) {
                return response.data
            }
        } catch (error){
                console.log(error)
                if (!error.response){
                    return rejectWithValue(error.message) 
                }
                return rejectWithValue(error.response.data)
            }
    })

export const getAllRolesSlice = createSlice({
    name: 'getAllRoles',
    initialState,
    error: null,
    reducers: {
        resetGetAllRolesError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllRoles.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllRoles.fulfilled, (state, action) => {
            state.loading = false
            state.roles = action.payload.roles
            state.role_count = action.payload.role_count
            state.isloaded = true
            state.error = false
        })
        builder.addCase(getAllRoles.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.isloaded = true
            state.error = true
        })
    }
})

export const { resetGetAllRolesError } = getAllRolesSlice.actions

export default getAllRolesSlice.reducer