import React from 'react'
import { Accordion, Row, Col } from 'react-bootstrap'
import { FaAws } from 'react-icons/fa'
import GetCredentials from './GetCredentials'

function UserRole(props) {
  return (
    <>
        <Accordion.Item eventKey={props.role.aws_role_arn} className='border-success text-primary'>
            <Accordion.Header >
                <FaAws style={{ marginRight: '10px', fontSize: '22px'}}/><strong>{props.role.aws_role_name} ({props.role.aws_account_number})</strong>
            </Accordion.Header>
            <Accordion.Body>
                    <Row>
                        <Col sm={4}>
                        <strong>Account Number:</strong>
                        </Col>
                        <Col>
                            {props.role.aws_account_number}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                        <strong>Account Tag:</strong>
                        </Col>
                        <Col>
                        {props.role.aws_account_tag}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                        <strong>Role Arn:</strong>
                        </Col>
                        <Col>
                        {props.role.aws_role_arn}
                        </Col>
                    </Row><Row>
                        <Col sm={4}>
                        <strong>Role Name:</strong>
                        </Col>
                        <Col>
                        {props.role.aws_role_name}
                        </Col>
                    </Row>
                    <hr></hr>
                    <GetCredentials data={props.role} />
                    </Accordion.Body>
        </Accordion.Item>
    </>
  )
}

export default UserRole