import React from 'react'
import { Alert, Button, Modal, Spinner, Tab, Tabs } from 'react-bootstrap'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';

import { getCredentials } from '../features/getCredentialsSlice';

function GetCredentials(props) {
    const [cliShow, setCliShow] = useState(false)
    const [consoleShow, setConsoleShow] = useState(false)
    const handleConsoleClose = () => setConsoleShow(false)
    const handleConsoleShow = () => {
        get_credentials()
        setConsoleShow(true)
    }
    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();

    const { loading, isLoaded, credentials, error, errorMessage } = useSelector((state) => state.getCredentials)


    function get_credentials(){
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'aws_account_number': props.data.aws_account_number,
                'aws_role_arn': props.data.aws_role_arn
            }
            dispatch(getCredentials(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }
    const handleCliShow = () => {
        get_credentials()
        setCliShow(true)
    }
    const handleCliClose = () => setCliShow(false)
    function handleOpenConsole() {
        window.open(credentials.console_credentials)
    }
   
  return (
    <>
        <Button variant="primary" className='mt-3' onClick={handleCliShow}>Get AWS CLI Credentials</Button>
            <Modal show={cliShow} onHide={handleCliClose}>
            <Modal.Header closeButton>
                <Modal.Title>AWS CLI/API Token #{props.data && props.data.aws_account_number}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {error && <Alert variant='danger' dismissible>
                        {errorMessage}
                        </Alert>}
                <Tabs
                    defaultActiveKey="WindowsCmd"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="WindowsCmd" title="Windows CMD">
                        {!error && isLoaded && 
                            <code>
                                set AWS_ACCESS_KEY_ID={isLoaded && credentials.cli_credentials.sessionId}<br></br>
                                set AWS_SECRET_ACCESS_KEY={isLoaded && credentials.cli_credentials.sessionKey}<br></br>
                                set AWS_SESSION_TOKEN={isLoaded && credentials.cli_credentials.sessionToken}
                            </code>
                        }
                    </Tab>
                    <Tab eventKey="PowerShell" title="PowerShell">
                        {!error && isLoaded && 
                            <code>
                                $env: AWS_ACCESS_KEY_ID={isLoaded && credentials.cli_credentials.sessionId}<br></br>
                                $env: AWS_SECRET_ACCESS_KEY={isLoaded && credentials.cli_credentials.sessionKey}<br></br>
                                $env: AWS_SESSION_TOKEN={isLoaded && credentials.cli_credentials.sessionToken}
                            </code>
                        }
                    </Tab>
                    <Tab eventKey="home" title="Mac/Linux">
                        {!error && isLoaded && 
                            <code>
                                    export AWS_ACCESS_KEY_ID={isLoaded && credentials.cli_credentials.sessionId}<br></br>
                                    export AWS_SECRET_ACCESS_KEY={isLoaded && credentials.cli_credentials.sessionKey}<br></br>
                                    export AWS_SESSION_TOKEN={isLoaded && credentials.cli_credentials.sessionToken}
                            </code>
                        }   
                    </Tab>
                </Tabs>
                {loading && <Spinner></Spinner>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleCliClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Button variant="warning" className='mt-3 mx-3' onClick={handleConsoleShow}>Get AWS Console Link</Button>
        <Modal show={consoleShow} onHide={handleConsoleClose}>
            <Modal.Header closeButton>
                <Modal.Title>AWS Console Link #{props.data && props.data.aws_account_number}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {error && <Alert variant='danger' dismissible>
                        {errorMessage}
                        </Alert>}
                Click on the Below Link to Open AWS Console
                {loading && <Spinner></Spinner>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleOpenConsole} disabled={loading || error}>
                    Open AWS Console
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default GetCredentials