import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    addUserMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const addUser = createAsyncThunk(
     'addUser',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
      }
      const body = {
        'user_email': data.user_email,
        'user_role': data.user_role,
      }
      console.log(data)

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/admin/user`, body, { headers })
        if (response.status === 200) {
            return response.data
        }
    } catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const addUserSlice = createSlice({
    name: 'addUser',
    initialState,
    error: null,
    reducers: {
        resetAddUser: state => {
            state.addUserMessage = null
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetAddUserError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(addUser.pending, (state) => {
            state.loading = true
        })
        builder.addCase(addUser.fulfilled, (state, action) => {
            state.loading = false
            state.addUserMessage = action.payload
            state.isSuccess = true
            state.error = false
        })
        builder.addCase(addUser.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.error = true
        })
    }
})

export const { resetAddUser, resetAddUserError } = addUserSlice.actions

export default addUserSlice.reducer