import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    credentials: [],
    loading: false,
    isLoaded: false,
    error: false,
    errorMessage: null
}

export const getCredentials = createAsyncThunk(
     'getCredentials',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
        'account_number': data.aws_account_number,
        'role_arn': data.aws_role_arn,
      }

      console.log(headers)
    
    try {

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/aws/credentials`, { headers })

        if (response.status === 200) {
            return response.data
        }
        } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const getCredentialsSlice = createSlice({
    name: 'getCredentials',
    initialState,
    error: null,
    reducers:{
        clearGetCredentialsError: (state) => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(getCredentials.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getCredentials.fulfilled, (state, action) => {
            state.loading = false
            state.credentials = action.payload
            state.isLoaded = true
            state.error = false
        })
        builder.addCase(getCredentials.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.isLoaded = true
            state.error = true
        })
    }
})

export const { resetGetCredentialsError } = getCredentialsSlice.actions
export default getCredentialsSlice.reducer

