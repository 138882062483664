import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    loginUser: [],
    loading: false,
    isLoaded: false,
    error: false,
    errorMessage: null
}

export const getUserData = createAsyncThunk(
     'userdetails',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
      }
    
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/user`, { headers })
        if (response.status === 200) {
            return {
                email: response.data.email,
                role: response.data.role,
                user_name: data.user_name,
            }
        }
    } catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const userSlice = createSlice({
    name: 'loginUser',
    initialState,
    error: null,
    extraReducers: builder => {
        builder.addCase(getUserData.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getUserData.fulfilled, (state, action) => {
            state.loading = false
            state.loginUser = action.payload
            state.isLoaded = true
            state.error = false
        })
        builder.addCase(getUserData.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.isLoaded = true
            state.error = true
        })
    }
})

export default userSlice.reducer