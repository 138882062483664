import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    validateEmailResponse: {},
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const validateEmail = createAsyncThunk(
    'validateEmail',
    async (data, {rejectWithValue}) => {

        const headers = {
            'access_token': data.access_token,
            'email': data.email,
        }

        const body = {
            'user_email': data.user_email,
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/admin/validate-email`, body, { headers })
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message)
            }
            return rejectWithValue(error.response.data)
        }
    })

export const validateEmailSlice = createSlice({
    name: 'validateEmail',
    initialState,
    error: null,
    reducers: {
        resetValidateEmail: state => {
            state.validateEmailResponse = {}
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetValidateEmailError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(validateEmail.pending, (state) => {
            state.loading = true
        })
        builder.addCase(validateEmail.fulfilled, (state, {payload}) => {
            state.loading = false
            state.isSuccess = true
            state.validateEmailResponse = payload
        })
        builder.addCase(validateEmail.rejected, (state, {payload}) => {
            state.loading = false
            state.error = true
            state.errorMessage = payload
        })
    }
})

export const { resetValidateEmail, resetValidateEmailError } = validateEmailSlice.actions

export default validateEmailSlice.reducer