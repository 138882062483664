import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    addRoleMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const addRole = createAsyncThunk(
     'addRole',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
      }
      const body = {
        'aws_account_number': data.aws_account_number,
        'aws_role_arn': data.aws_role_arn,
        'aws_role_name': data.aws_role_name,
        'aws_account_tag': data.aws_account_tag,
        'admin_emails': data.admin_emails,
        'session_timeout': data.session_timeout,
        'user_emails': []
      }
      console.log(data)

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/admin/role`, body, { headers })
        if (response.status === 200) {
            return response.data
        }
    } catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const addRoleSlice = createSlice({
    name: 'addRole',
    initialState,
    error: null,
    reducers: {
        resetAddRole: state => {
            state.addRoleMessage = null
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetAddRoleError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(addRole.pending, (state) => {
            state.loading = true
        })
        builder.addCase(addRole.fulfilled, (state, action) => {
            state.loading = false
            state.addRoleMessage = action.payload
            state.isSuccess = true
            state.error = false
        })
        builder.addCase(addRole.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.isSuccess = false
            state.error = true
        })
    }
})

export const { resetAddRole, resetAddRoleError } = addRoleSlice.actions
export default addRoleSlice.reducer