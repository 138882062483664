import React from 'react';
import { Accordion } from 'react-bootstrap';

function ListAdminRoles() {  
    return (
      <Accordion defaultActiveKey="0">

      </Accordion>
    );
  
}

export default ListAdminRoles