import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    addUserAccessMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const addUserAccess = createAsyncThunk(
    'addUserAccess',
    async (data, {rejectWithValue}) => {

        const headers = {
            'access_token': data.access_token,
            'email': data.email,
        }

        const body = {
            'user_email': data.user_email,
            'aws_account_number': data.aws_account_number,
            'aws_role_arn': data.aws_role_arn,
        }

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/admin/user-access`, body, { headers })
        if (response.status === 200) {
            return response.data
        }
    }
    catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
    })

export const addUserAccessSlice = createSlice({
    name: 'addUserAccess',
    initialState,
    error: null,
    reducers: {
        resetAddUserAccess: state => {
            state.addUserAccessMessage = null
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetAddUserAccessError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(addUserAccess.pending, (state) => {
            state.loading = true
        })
        builder.addCase(addUserAccess.fulfilled, (state, action) => {
            state.loading = false
            state.isSuccess = true
            state.addUserAccessMessage = action.payload
        })
        builder.addCase(addUserAccess.rejected, (state, action) => {
            state.loading = false
            state.error = true
            state.errorMessage = action.payload
        })
    }
})

export const { resetAddUserAccess, resetAddUserAccessError } = addUserAccessSlice.actions

export default addUserAccessSlice.reducer