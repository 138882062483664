import React from 'react'
import { Container, Accordion, Pagination, Row, Spinner, Form, Card, Alert } from 'react-bootstrap'
import UserRole from '../components/UserRole'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { getAllUserRoles } from '../features/getAllUserRolesSlice'

function ListUserRolePage() {

    const { instance, inProgress, accounts } = useMsal();
    const dispatch = useDispatch()
    const { loading, userRoles, error, errorMessage } = useSelector((state) => state.getAllUserRoles)
    const [query, setQuery] = useState('');
    const [filteredRoles, setFilteredRoles] = useState([])
    const [showFilteredRoles, setShowFilteredRoles] = useState(false)

    const handleInputChange = (e) => {
        setQuery(e.target.value);
        const roles = userRoles.filter(role => role.aws_role_arn.toLowerCase().includes(e.target.value.toLowerCase()));
        setFilteredRoles(roles);
        setShowFilteredRoles(true)
        // Use the filteredRoles array as needed
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = showFilteredRoles ? filteredRoles.slice(indexOfFirstItem, indexOfLastItem) : userRoles.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = showFilteredRoles ? Math.ceil(filteredRoles.length / itemsPerPage) : Math.ceil(userRoles.length / itemsPerPage)

    const getAllUserRolesView = currentItems.map( role => {
        return <UserRole role={role} key={role.aws_role_arn}/>
    })

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
            }
            dispatch(getAllUserRoles(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
      }, [instance, accounts, inProgress, dispatch]);

      const handleClick = (number) => {
        setCurrentPage(number);
      }

      let items = [];

      if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handleClick(i)}>
            {i}
          </Pagination.Item>
        );
      }
      } else {
        items.push(
          <Pagination.Item key={1} active={1 === currentPage} onClick={() => handleClick(1)}>
            1
          </Pagination.Item>
        );
    
        if (currentPage > 4) {
          items.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }
    
        const startPage = Math.max(2, currentPage - 2);
        const endPage = Math.min(totalPages - 1, currentPage + 2);
    
        for (let i = startPage; i <= endPage; i++) {
          items.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handleClick(i)}>
              {i}
            </Pagination.Item>
          );
        }
    
        if (currentPage < totalPages - 3) {
          items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }
    
        items.push(
          <Pagination.Item key={totalPages} active={totalPages === currentPage} onClick={() => handleClick(totalPages)}>
            {totalPages}
          </Pagination.Item>
        );
      }

  return (
    <Container>
        <p className="lead">My AWS Roles</p>
        <hr></hr>
        { loading && <Spinner animation="border" /> }
        { error && <Alert variant='danger'>{errorMessage}</Alert> }
        <Card className="card border-dark mb-3">
            <Container className="card-header">
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Search Role</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Search By Role Name" 
                            value={query}
                            onChange={handleInputChange}
                            />
                    </Form.Group>
                </Form>
            </Container>
        </Card>
        {/* {showFilteredRoles ? <>
        <Accordion>{getAlluserRolesFilteredview}</Accordion>
        <Row>
            <Pagination className='pagination'>
            <Pagination.First onClick={() => handleClick(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1} />
            {items}
            <Pagination.Next onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handleClick(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
      </Row>
        </>: */}
        <>
        <Accordion>
            {getAllUserRolesView}
        </Accordion>
        <br></br>
        <Row>
        <Pagination className='pagination'>
        <Pagination.First onClick={() => handleClick(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1} />
        {items}
        <Pagination.Next onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handleClick(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </Row>
      </>
    </Container>
  )
}

export default ListUserRolePage