import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    updateUserMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const updateUser = createAsyncThunk(
        'updateUser',
        async (data, {rejectWithValue}) => {

        const headers = {
            'access_token': data.access_token,
            'email': data.email,
        }

        const body = {
            'user_email': data.user_email,
            'user_role': data.user_role,
        }

    try {
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/admin/user`,body, { headers })
        if (response.status === 200) {
            return response.data
        }
    }
    catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
    })

export const updateUserSlice = createSlice({
    name: 'updateUser',
    initialState,
    error: null,
    reducers: {
        resetUpdateUser: state => {
            state.updateUserMessage = null
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetUpdateUserError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder =>{
        builder.addCase(updateUser.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.loading = false
            state.updateUserMessage = action.payload
            state.isSuccess = true
            state.error = false
        })
        builder.addCase(updateUser.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.error = true
        })
    }
})


export const { resetUpdateUser, resetUpdateUserError } = updateUserSlice.actions

export default updateUserSlice.reducer