import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    userRoles: [],
    roleCount: 0,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const getAllUserRoles = createAsyncThunk(
    'getAllUserRoles',
    async (data, {rejectWithValue}) => {

        const headers = {
            'access_token': data.access_token,
            'email': data.email,
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/roles`, { headers })
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message)
            }
            return rejectWithValue(error.response.data)
        }
    })

export const getAllUserRolesSlice = createSlice({
    name: 'getAllUserRoles',
    initialState,
    error: null,
    reducers: {
        resetGetAllUserRoles: state => {
            state.userRoles = []
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetGetAllUserRolesError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllUserRoles.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllUserRoles.fulfilled, (state, { payload }) => {
            state.userRoles = payload.roles
            state.roleCount = payload.role_count
            state.loading = false
            state.isSuccess = true
        })
        builder.addCase(getAllUserRoles.rejected, (state, { payload }) => {
            state.error = true
            state.errorMessage = payload
            state.loading = false
        })
    }
})

export const { resetGetAllUserRoles, resetGetAllUserRolesError } = getAllUserRolesSlice.actions

export default getAllUserRolesSlice.reducer