import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    users: [],
    user_count: 0,
    loading: false,
    isloaded: false,
    error: false,
    errorMessage: null
}

export const getAllUsers = createAsyncThunk(
        'getAllUsers',
        async (data, {rejectWithValue}) => {
        const headers = {
            'access_token': data.access_token,
            'email': data.email,
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/admin/users`, { headers })
            if (response.status === 200) {
                return response.data
            }
        } catch (error){
                console.log(error)
                if (!error.response){
                    return rejectWithValue(error.message) 
                }
                return rejectWithValue(error.response.data)
            }
    })

export const getAllUserSlice = createSlice({
    name: 'getAllUsers',
    initialState,
    error: null,
    reducers: {
        resetGetAllUsersError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllUsers.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            state.loading = false
            state.users = action.payload.users
            state.user_count = action.payload.user_count
            state.isloaded = true
            state.error = false
        })
        builder.addCase(getAllUsers.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.isloaded = true
            state.error = true
        })
    }
})

export const { resetGetAllUsersError } = getAllUserSlice.actions

export default getAllUserSlice.reducer