import {configureStore} from "@reduxjs/toolkit"
import userReducer from "./features/userSlice"
import addRoleReducer from "./features/addRoleSlice"
import getAllRolesReducer from "./features/getAllRolesSlice"
import getRoleReducer from "./features/getRoleSlice"
import deleteRoleReducer from "./features/deleteRoleSlice"
import updateRoleReducer from "./features/updateRoleSlice"
import addUserReducer from "./features/addUserSlice"
import updateUserReducer from "./features/updateUserSlice"
import getAllUserReducer from "./features/getAllUserSlice"
import deleteUserReducer from "./features/deleteUserSlice"
import validateEmailReducer from "./features/validateEmailSlice"
import addUserAccessReducer from "./features/addUserAccessSlice"
import removeUserAccessReducer from "./features/removeUserAccessSlice"
import getAllUserRolesReducer from "./features/getAllUserRolesSlice"
import getCredentialsReducer from "./features/getCredentialsSlice"

export const store = configureStore({
    reducer: {
        user: userReducer,
        addRole: addRoleReducer,
        getAllRoles: getAllRolesReducer,
        getRole: getRoleReducer,
        deleteRole: deleteRoleReducer,
        updateRole: updateRoleReducer,
        addUser: addUserReducer,
        updateUser: updateUserReducer,
        getAllUser: getAllUserReducer,
        deleteUser: deleteUserReducer,
        validateEmail: validateEmailReducer,
        addUserAccess: addUserAccessReducer,
        removeUserAccess: removeUserAccessReducer,
        getAllUserRoles: getAllUserRolesReducer,
        getCredentials: getCredentialsReducer,
    },
})
