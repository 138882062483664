import React from 'react'
import { Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { AuthenticatedTemplate } from '@azure/msal-react';

function HomePage() {

  const { loginUser, isLoaded, error, loading} = useSelector((state) => state.user)
  return (
    <AuthenticatedTemplate>
      <Row>
      <p className="lead">Welcome {isLoaded && loginUser.user_name} !!</p>
        <hr></hr>
       { loading && <Spinner variant="primary"/>}
        
        { !error && isLoaded &&
        <p className="lead">
                              In Order to Access an Account click on Access AWS Role Tab.
                          </p>
        }
        <pre><code>
        </code></pre>
      </Row>
    </AuthenticatedTemplate>
  )
}

export default HomePage