import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    updateRoleMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const updateRole = createAsyncThunk(
     'updateRole',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
      }
      console.log(data)

      const body = {
        'aws_account_number': data.aws_account_number,
        'aws_role_arn': data.aws_role_arn,
        'aws_role_name': data.aws_role_name,
        'aws_account_tag': data.aws_account_tag,
        'admin_emails': data.admin_emails,
        'session_timeout': data.session_timeout
      }

    try {
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/admin/role`, body, { headers })
        if (response.status === 200) {
            return response.data
        }
    } catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const updateRoleSlice = createSlice({
    name: 'updateRole',
    initialState,
    error: null,
    reducers: {
        resetUpdateRole: state => {
            state.updateRoleMessage = null
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetUpdateRoleError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(updateRole.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateRole.fulfilled, (state, action) => {
            state.loading = false
            state.isSuccess = true
            state.updateRoleMessage = action.payload.message
        })
        builder.addCase(updateRole.rejected, (state, action) => {
            state.loading = false
            state.error = true
            state.errorMessage = action.payload
        })
    }
})

export const { resetUpdateRole, resetUpdateRoleError } = updateRoleSlice.actions

export default updateRoleSlice.reducer