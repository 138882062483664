import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { resetGetAllUsersError } from '../features/getAllUserSlice'
import { Container, Row, Table, Pagination, Spinner, Alert, Button, Card, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import { getAllUsers } from '../features/getAllUserSlice'


function ListAllUsers() {

  const { instance, inProgress, accounts } = useMsal();
  const { loading, users, error, errorMessage } = useSelector((state) => state.getAllUser)
  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const handleClick = (number) => {
    setCurrentPage(number);
  }

  const [query, setQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([])
  const [showFilteredUser, setShowFilteredUser] = useState(false)

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    const userData = users.filter(user => user.email.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredUsers(userData);
    setShowFilteredUser(true)
    // Use the filteredRoles array as needed
};

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = showFilteredUser ? filteredUsers.slice(indexOfFirstItem, indexOfLastItem) : users.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = showFilteredUser ? Math.ceil(filteredUsers.length / itemsPerPage) : Math.ceil(users.length / itemsPerPage);


  const table_body = currentItems.map( user => {
    return (
                <tr key={user.email}>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  <LinkContainer to={{ pathname: "/admin/user", search: `user_email=${user.email}&user_role=${user.role}`}} key={user.email}>
                    <Button variant='primary'>Manage User</Button>
                  </LinkContainer>
                </td>
            </tr>
      )}
)

  function handleOnClose() {
    dispatch(resetGetAllUsersError())
  }

  useEffect(() => {
    const accessTokenRequest = {
    scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
    account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
    instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        const data = {
            'access_token': `Bearer ${accessToken}`,
            'email': instance.getActiveAccount().username,
        }
        dispatch(getAllUsers(data))
        })
        .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
        }
        });
    }
  }, [instance, accounts, inProgress, dispatch]);

  let items = [];

  if (totalPages <= 7) {
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handleClick(i)}>
          {i}
        </Pagination.Item>
      );
    }
    } else {
      items.push(
        <Pagination.Item key={1} active={1 === currentPage} onClick={() => handleClick(1)}>
          1
        </Pagination.Item>
      );
  
      if (currentPage > 4) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
  
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
  
      for (let i = startPage; i <= endPage; i++) {
        items.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handleClick(i)}>
            {i}
          </Pagination.Item>
        );
      }
  
      if (currentPage < totalPages - 3) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
  
      items.push(
        <Pagination.Item key={totalPages} active={totalPages === currentPage} onClick={() => handleClick(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }
  



  return (
    <>
        <h4>Manage User Access</h4>
        <hr></hr>
        {loading && <Spinner></Spinner> }
        { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnClose}>{errorMessage}</Alert>}
        <Card className="card border-dark mb-3">
            <Container className="card-header">
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Search User</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Search user by email" 
                            value={query}
                            onChange={handleInputChange}
                            />
                    </Form.Group>
                </Form>
            </Container>
      </Card>

        <Container>

      <Row>
        <Table className='table table-hover'>
            <thead>
                <tr className='table-active'>
                <th>User Email</th>
                <th>User Role</th>
                <th>Manage User</th>
                </tr>
            </thead>
            <tbody>
                {table_body}
            </tbody>

        </Table>
      </Row>
      <Row>
        <Pagination className='pagination'>
        <Pagination.First onClick={() => handleClick(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1} />
        {items}
        <Pagination.Next onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handleClick(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </Row>    
      </Container>
    </>
  )
}

export default ListAllUsers