import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";


const initialState = {
    deleteUserMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const deleteUser = createAsyncThunk(
    'deleteUser',
    async (data, {rejectWithValue}) => {

        const headers = {
            'access_token': data.access_token,
            'email': data.email,
            'user_email': data.user_email,
        }

        console.log(headers)

        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_API_URL}/admin/user`, { headers })
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message)
            }
            return rejectWithValue(error.response.data)
        }
    })

export const deleteUserSlice = createSlice({
    name: 'deleteUser',
    initialState,
    error: null,
    reducers: {
        resetDeleteUser: state => {
            state.deleteUserMessage = null
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetDeleteUserError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder =>{
        builder.addCase(deleteUser.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.deleteUserMessage = action.payload
            state.loading = false
            state.isSuccess = true
            state.error = false
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.error = true
        })
    }
})

export const { resetDeleteUser, resetDeleteUserError } = deleteUserSlice.actions

export default deleteUserSlice.reducer