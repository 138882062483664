import React from 'react'
import { Container, Nav } from 'react-bootstrap'
import ListAdminRoles from '../components/ListAdminRoles'
 

function ManageAdmins() {
  const [ tab, setTab ] = React.useState('tab1')

    function handleOnClick(tabId){
        setTab(tabId)
    }

  return (
    <>
    <h3>Manage Admins</h3>
    <hr></hr>
  <Nav variant="tabs" defaultActiveKey={tab} justify>
    <Nav.Item  onClick={() => handleOnClick('tab1')}>
      <Nav.Link eventKey='tab1'> <b>Add Admin</b></Nav.Link>
    </Nav.Item>
    <Nav.Item  onClick={() => handleOnClick('tab2')}>
      <Nav.Link eventKey='tab2'><b>List Admin</b></Nav.Link>
    </Nav.Item>
  </Nav>
  { tab === 'tab1' && <Container><ListAdminRoles/></Container>}
  { tab === 'tab2' && <Container>sas</Container>}
  </>
  )
}

export default ManageAdmins