import React from 'react'
import { Row } from 'react-bootstrap'

function ErrorPage() {
  return (
    <Row>
      <h3>Error 404</h3>
        <hr></hr>
       <p>Page Not found!</p>
      </Row>
  )
}

export default ErrorPage