import React from 'react'
import { Card, Container, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { getAllRoles } from '../features/getAllRolesSlice'
import { FaCheck } from 'react-icons/fa';
import { validateEmail } from '../features/validateEmailSlice'
import { addUserAccess } from '../features/addUserAccessSlice';
import { resetAddUserAccess, resetAddUserAccessError } from '../features/addUserAccessSlice';
import { resetValidateEmail, resetValidateEmailError } from '../features/validateEmailSlice';

function AddUserAccess() {

    const [validated, setValidated] = useState(false);
    const { instance, inProgress, accounts } = useMsal();
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(
        {
            role_arn : "",
            user_email: "",
        }
      )

    function handleChange(event) {
        console.log(event.target.value)
        console.log(event.target.name)
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    function handOnClose() {
        dispatch(resetAddUserAccess())
        dispatch(resetAddUserAccessError())
    }

    const getAllRolesData = useSelector((state) => state.getAllRoles)
    const ValidateEmailData = useSelector((state) => state.validateEmail)
    const addUserAccessResponse = useSelector((state) => state.addUserAccess)
    
    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
            }
            dispatch(getAllRoles(data))
            dispatch(resetValidateEmail())
            dispatch(resetValidateEmailError())
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
      }, [instance, accounts, inProgress, dispatch]);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;


        if (form.checkValidity() === false) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }

        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
            };

        const filteredRoles = getAllRolesData.roles.filter(role => role.aws_role_arn === formData.role_arn);
        console.log(filteredRoles[0])

        if (inProgress === InteractionStatus.None && form.checkValidity()) {
           instance
               .acquireTokenSilent(accessTokenRequest)
               .then((accessTokenResponse) => {
                   let accessToken = accessTokenResponse.accessToken;
                   const data = {
                       'access_token': `Bearer ${accessToken}`,
                       'email': instance.getActiveAccount().username,
                       'user_email': formData.user_email,
                       'aws_role_arn': formData.role_arn,
                       'aws_account_number': filteredRoles[0].aws_account_number,
                   }
                   dispatch(addUserAccess(data))
                       .catch((error) => {
                           if (error instanceof InteractionRequiredAuthError) {
                               instance.acquireTokenRedirect(accessTokenRequest);
                           }
                       })
               })         
       }
    }

    const handleValidateEmail = (event) => {
        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
            };

        if (inProgress === InteractionStatus.None) {
           instance
               .acquireTokenSilent(accessTokenRequest)
               .then((accessTokenResponse) => {
                   let accessToken = accessTokenResponse.accessToken;
                   const data = {
                       'access_token': `Bearer ${accessToken}`,
                       'email': instance.getActiveAccount().username,
                       'user_email': formData.user_email,
                   }
                   dispatch(validateEmail(data))
                       .catch((error) => {
                           if (error instanceof InteractionRequiredAuthError) {
                               instance.acquireTokenRedirect(accessTokenRequest);
                           }
                       })
               })         
       }
    }
  return (
    <>
    <h4>Add User Access</h4>
    <hr></hr>
    { addUserAccessResponse.loading && <Spinner animation="border" />}
    { getAllRolesData.loading && <Spinner animation="border" />}
    { getAllRolesData.error && <Alert variant="danger" dismissible >{getAllRolesData.errorMessage}</Alert>}
    { addUserAccessResponse.isSuccess && <Alert variant="success" dismissible onClose={handOnClose}>{addUserAccessResponse.addUserAccessMessage}</Alert>}
    { addUserAccessResponse.error && <Alert variant="danger" dismissible onClose={handOnClose}>{addUserAccessResponse.errorMessage}</Alert>}
    <Card className="card border-dark mb-3">
        <Container className="card-header">
            Add User Access
        </Container>
        <Container className="card-body">
            <Form validated={validated} onSubmit={handleSubmit}>
                <Row>
                <Form.Group as={Row}>
                    <Col sm={4}><Form.Label>Select AWS Role:</Form.Label></Col>
                    <Col sm={6}>
                        <Form.Select 
                            aria-label="Default select example"
                            id="roleArn"
                            name="role_arn"
                            value={formData.role_arn}
                            onChange={handleChange}
                            required
                        >
                        <option label='Select AWS Role ...'></option>
                        {   !getAllRolesData.error && getAllRolesData.isloaded &&
                            getAllRolesData.roles.map( role => {
                                return <option key={role.aws_role_arn} value={role.aws_role_arn}>
                                    {role.aws_role_name}
                                </option>
                            })
                        }
                        </Form.Select>
                        </Col>
                        </Form.Group>
                    </Row>
                    <br></br>
                    <Row>
                    <Form.Group as={Row}>
                            <Col sm={4}><Form.Label>User Email/s:</Form.Label></Col>
                            <Col sm={6}>
                                <Form.Control
                                type="email"
                                name="user_email"
                                value={formData.user_email}
                                onChange={handleChange}
                                placeholder='User Email ID'
                                required
                                onBlur={handleValidateEmail}
                                style={{
                                    borderColor: ValidateEmailData.validateEmailResponse.validated ? 'green' : ''
                                }}
                                />
                                { ValidateEmailData.isSuccess && !ValidateEmailData.validateEmailResponse.validated && 
                                    <Form.Text className="text-mutted" style={{ color: 'red' }}>User Not Present, To add user please raise a request to mosaic team</Form.Text> 
                                }
                            </Col>
                            <Col sm={2}>
                                { ValidateEmailData.validateEmailResponse.validated && <FaCheck style={{ color: 'green' }}/>}
                                { ValidateEmailData.loading && <><Spinner animation="border" style={{ marginRight: '10px'}}/></>}
                                
                            </Col>
                        </Form.Group>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={5}>
                        <Button type="submit" disabled={ValidateEmailData.loading || !ValidateEmailData.validateEmailResponse.validated || addUserAccessResponse.loading} variant='warning'>Add User Access</Button>
                        </Col>
                    </Row>
            </Form>
        </Container>
    </Card>
    </>
  )
}

export default AddUserAccess