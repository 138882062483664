import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    removeUserAccessMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const removeUserAccess = createAsyncThunk(
    'removeUserAccess',
    async (data, {rejectWithValue}) => {

        const headers = {
            'access_token': data.access_token,
            'email': data.email,
        }

        const body = {
            'aws_account_number': data.aws_account_number,
            'aws_role_arn': data.aws_role_arn,
            'user_emails': data.user_emails
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/admin/user-access`, body, { headers })
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message)
            }
            return rejectWithValue(error.response.data)
        }
    })

export const removeUserAccessSlice = createSlice({
    name: 'removeUserAccess',
    initialState,
    error: null,
    reducers: {
        resetRemoveUserAccess: state => {
            state.removeUserAccessMessage = null
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetRemoveUserAccessError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(removeUserAccess.pending, (state) => {
            state.loading = true
        })
        builder.addCase(removeUserAccess.fulfilled, (state, action) => {
            state.loading = false
            state.isSuccess = true
            state.removeUserAccessMessage = action.payload
        })
        builder.addCase(removeUserAccess.rejected, (state, action) => {
            state.loading = false
            state.error = true
            state.errorMessage = action.payload
        })
    }
})

export const {resetRemoveUserAccess, resetRemoveUserAccessError} = removeUserAccessSlice.actions

export default removeUserAccessSlice.reducer