import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    role: {},
    loading: false,
    isloaded: false,
    error: false,
    errorMessage: null
}

export const getRole = createAsyncThunk(
        'getRole',
        async (data, {rejectWithValue}) => {
        const headers = {
            'access_token': data.access_token,
            'email': data.email,
            'arn': data.aws_role_arn
            }

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/admin/role`, { headers })
            if (response.status === 200) {
                return response.data
            }
        } catch (error){
                console.log(error)
                if (!error.response){
                    return rejectWithValue(error.message) 
                }
                return rejectWithValue(error.response.data)
            }
    })

export const getRoleSlice = createSlice({
    name: 'getRole',
    initialState,
    error: null,
    reducers: {
        resetGetRoleError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(getRole.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.loading = false
            state.role = action.payload
            state.isloaded = true
            state.error = false
        })
        builder.addCase(getRole.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.isloaded = true
            state.error = true
        })
    }
})

export const { resetGetRoleError } = getRoleSlice.actions

export default getRoleSlice.reducer