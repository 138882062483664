import React from 'react'
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { getRole } from '../features/getRoleSlice';
import { deleteRole } from '../features/deleteRoleSlice';
import { resetDeleteRole } from '../features/deleteRoleSlice';
import { resetGetRoleError } from '../features/getRoleSlice';
import { Row, Spinner, Card, Container, Table, Button, Col, Modal, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';



function AWSRole() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const aws_role_arn = queryParams.get('arn')

    const { instance, inProgress, accounts } = useMsal();
    const { loading, role, error, errorMessage } = useSelector((state) => state.getRole)
    const deleteRoleData = useSelector((state) => state.deleteRole)
    const dispatch = useDispatch()

    const [showApprove, setApproveShow] = useState(false);

    const handleApproveClose = () => setApproveShow(false);
    const handleApproveShow = () => setApproveShow(true);

    function handleOnClose() {
        dispatch(resetGetRoleError())
      }

    function handleDeleteRoleOnClose() {
        dispatch(resetDeleteRole())
      }
    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'aws_role_arn': aws_role_arn
            }
            dispatch(getRole(data))
            dispatch(resetDeleteRole())
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
      }, [instance, accounts, inProgress, dispatch, aws_role_arn]);

    function handleRoleDelete(){
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'aws_role_arn': role.aws_role_arn,
                'aws_account_number': role.aws_account_number,
            }
            dispatch(deleteRole(data))
            setApproveShow(false)
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }

  return (
    <>
        <h4>AWS Role</h4>
        <hr></hr>
            {loading && <Spinner animation="border" />}
            { deleteRoleData.loading && <Spinner animation="border" />}
            {deleteRoleData.error && <Alert variant='danger' dismissible onClick={handleDeleteRoleOnClose}>{deleteRoleData.errorMessage}</Alert>}
            {error && <Alert variant='danger' dismissible onClick={handleOnClose}>{errorMessage}</Alert>}
            {deleteRoleData.isSuccess && <Alert variant='success' dismissible onClick={handleDeleteRoleOnClose}>{deleteRoleData.deleteRoleMessage}</Alert>}
            {!loading && !error && 
                <Card className="card border-dark mb-3">
                    <Container className="card-header">
                        AWS Role Details
                    </Container>
                    <Container className="card-body">
                        <Row>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td><strong>Role ARN:</strong></td>
                                        <td>{role.aws_role_arn}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Role Name:</strong></td>
                                        <td>{role.aws_role_name}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Account Number:</strong></td>
                                        <td>{role.aws_account_number}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Account Tag:</strong></td>
                                        <td>{role.aws_account_tag}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Session Timeout (In hours):</strong></td>
                                        <td>{role.session_timeout}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Role Admins:</strong></td>
                                        <td>{role.admin_emails && role.admin_emails.map((email, index) => (
                                            <p key={index}>{email}</p>))}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                        </Row>
                        <LinkContainer to={{ pathname: "/admin/role/update", search: `arn=${role.aws_role_arn}&account_number=${role.aws_account_number}&account_tag=${role.aws_account_tag}&role_name=${role.aws_role_name}&session_timeout=${role.session_timeout}`}}>
                        <Button variant="primary" className='mx-3' disabled = {deleteRoleData.isSuccess}>
                            Update Role
                        </Button>
                        </LinkContainer>
                        <Button variant="danger" onClick={handleApproveShow} disabled = {deleteRoleData.isSuccess}>
                            Delete Role
                        </Button> 
                        <Modal show={showApprove} onHide={handleApproveClose}>
                            <Modal.Header closeButton>
                            <Modal.Title>Are you sure you?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td><strong>Role Name:</strong></td>
                                            <td>{role.aws_role_name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Role Arn:</strong></td>
                                            <td>{role.aws_role_arn}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            <Button variant="primary" className='mx-3' onClick={handleApproveClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" type="submit" onClick={handleRoleDelete}>
                                Delete Role
                            </Button>
                        </Modal.Body>
                    </Modal>                  
                    </Container>
                </Card>
            }
      </>
    
  )
}

export default AWSRole