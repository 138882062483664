import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    deleteRoleMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const deleteRole = createAsyncThunk(
     'deleteRole',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
        'arn': data.aws_role_arn,
        'account_number': data.aws_account_number,
      }

      console.log(headers)

    try {
        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_API_URL}/admin/role`, { headers })
        if (response.status === 200) {
            return response.data
        }
    } catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const deleteRoleSlice = createSlice({
    name: 'deleteRole',
    initialState,
    error: null,
    reducers: {
        resetDeleteRole: state => {
            state.deleteRoleMessage = null
            state.loading = false
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
        },
        resetDeleteRoleError: state => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: builder => {
        builder.addCase(deleteRole.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteRole.fulfilled, (state, action) => {
            state.loading = false
            state.deleteRoleMessage = action.payload
            state.isSuccess = true
            state.error = false
        })
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.loading = false
            state.errorMessage = action.payload
            state.error = true
        })
    }})

export const { resetDeleteRole, resetDeleteRoleError } = deleteRoleSlice.actions

export default deleteRoleSlice.reducer