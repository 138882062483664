import React from 'react'
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'

import { resetDeleteRole } from '../features/deleteRoleSlice';
import { Row, Spinner, Card, Container, Table, Button, Col, Modal, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { deleteUser } from '../features/deleteUserSlice';
import { resetDeleteUser } from '../features/deleteUserSlice';



function User() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user_email = queryParams.get('user_email')
    const user_role = queryParams.get('user_role')

    const { instance, inProgress, accounts } = useMsal();
    const deleteUserData = useSelector((state) => state.deleteUser)
    const dispatch = useDispatch()

    const [showApprove, setApproveShow] = useState(false);

    const handleApproveClose = () => setApproveShow(false);
    const handleApproveShow = () => setApproveShow(true);


    function handleDeleteUserOnClose() {
        dispatch(resetDeleteRole())
      }

    useEffect(() => {
        dispatch(resetDeleteUser())
    }, [dispatch])

    function handleUserDelete(){
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'user_email': user_email,
                'user_role': user_role,
            }
            dispatch(deleteUser(data))
            setApproveShow(false)
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }

  return (
    <>
        <h4>AWS User</h4>
        <hr></hr>
            { deleteUserData.loading && <Spinner animation="border" />}
            {deleteUserData.isSuccess && <Alert variant='success' dismissible onClick={handleDeleteUserOnClose}>{deleteUserData.deleteUserMessage}</Alert>}
                <Card className="card border-dark mb-3">
                    <Container className="card-header">
                        AWS User Details
                    </Container>
                    <Container className="card-body">                      
                            <Row>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td><strong>User Email:</strong></td>
                                        <td>{user_email}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>User Role:</strong></td>
                                        <td>{user_role}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                        </Row>
                        <LinkContainer to={{ pathname: "/admin/user/update", search: `user_email=${user_email}&user_role=${user_role}`}}>
                        <Button variant="primary" className='mx-3' disabled = {deleteUserData.isSuccess}>
                            Update User
                        </Button>
                        </LinkContainer>
                        <Button variant="danger" onClick={handleApproveShow} disabled = {deleteUserData.isSuccess}>
                            Delete User
                        </Button> 
                        <Modal show={showApprove} onHide={handleApproveClose}>
                            <Modal.Header closeButton>
                            <Modal.Title>Are you sure you?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Table>
                                    <tbody>
                                        <tr>
                                            <td><strong>User Email:</strong></td>
                                            <td>{user_email}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>User Role:</strong></td>
                                            <td>{user_role}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            <Button variant="primary" className='mx-3' onClick={handleApproveClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" type="submit" onClick={handleUserDelete}>
                                Delete User
                            </Button>
                        </Modal.Body>
                    </Modal>                  
                    </Container>
                </Card>
      </>
    
  )
}

export default User