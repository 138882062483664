import React from 'react'
import { Card, Container, Form, Row, Col, Button, Spinner, Alert, Modal, Table } from 'react-bootstrap'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { getAllRoles } from '../features/getAllRolesSlice'
import { resetValidateEmail, resetValidateEmailError } from '../features/validateEmailSlice';
import { FaMinus } from 'react-icons/fa';
import { resetRemoveUserAccess, resetRemoveUserAccessError } from '../features/removeUserAccessSlice';
import { removeUserAccess } from '../features/removeUserAccessSlice';

function RemoveUserAccess() {

    const [validated, setValidated] = useState(false);
    const [role, setRole] = useState({});
    const [emails, setEmails] = useState([])
    const [showUser, setShowUser] = useState(false);
    const [showUserError, setShowUserError] = useState(false);
    const [uniqueToEmails, setUniqueToEmails] = useState([]);
    const { instance, inProgress, accounts } = useMsal();
    const [showApprove, setApproveShow] = useState(false);
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(
        {
            role_arn : "",
        }
      )
    
    useEffect(() => {
        const filteredRoles = getAllRolesData.roles.filter(role => role.aws_role_arn === formData.role_arn)
        if (filteredRoles[0] !== undefined){
            setUniqueToEmails(filteredRoles[0].user_emails.filter(email => !emails.includes(email)))
        }
    }, [emails, formData.role_arn])

    const handleApproveClose = () => setApproveShow(false);
    const handleApproveShow = (event) =>{
        event.preventDefault()
        const form = event.currentTarget;


        if (form.checkValidity() === false) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }
        setApproveShow(true);
    } 

    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
        const filteredRoles = getAllRolesData.roles.filter(role => role.aws_role_arn === event.target.value);
        setRole(filteredRoles[0]);
        setShowUser(true);
        if (filteredRoles[0] === undefined){
            setShowUserError(true)
        }
        else if (filteredRoles[0].user_emails.length === 0){
            setShowUserError(true)
        }
        else{
            setShowUserError(false)
            setEmails(filteredRoles[0].user_emails)
        }
    }


    const getAllRolesData = useSelector((state) => state.getAllRoles)

    const {removeUserAccessMessage, loading, isSuccess, error, errorMessage} = useSelector((state) => state.removeUserAccess)
    
    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
            }
            dispatch(getAllRoles(data))
            dispatch(resetValidateEmail())
            dispatch(resetValidateEmailError())
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
      }, [instance, accounts, inProgress, dispatch, removeUserAccessMessage]);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;


        if (form.checkValidity() === false) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }
        const filteredRoles = getAllRolesData.roles.filter(role => role.aws_role_arn === formData.role_arn);

        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
            };

        if (inProgress === InteractionStatus.None && form.checkValidity()) {
           instance
               .acquireTokenSilent(accessTokenRequest)
               .then((accessTokenResponse) => {
                   let accessToken = accessTokenResponse.accessToken;
                   const data = {
                       'access_token': `Bearer ${accessToken}`,
                       'email': instance.getActiveAccount().username,
                       'user_emails': emails,
                       'aws_role_arn': formData.role_arn,
                       'aws_account_number': filteredRoles[0].aws_account_number,
                   }
                   dispatch(removeUserAccess(data))
                       .catch((error) => {
                           if (error instanceof InteractionRequiredAuthError) {
                               instance.acquireTokenRedirect(accessTokenRequest);
                           }
                       })
               })         
       }
       handleApproveClose()

        
    }

    function handleEmailChange(index, event) {
        const newEmail = event.target.value;
        const newEmails = emails.map((email, i) => (i === index ? newEmail : email));
        setEmails(newEmails);
    }

    const handleRemoveEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
      };

    function handleOnClose() {
        dispatch(resetRemoveUserAccess())
        dispatch(resetRemoveUserAccessError())
    }

  return (
    <>
    <h4>Remove User Access</h4>
    <hr></hr>
    { loading && <Spinner animation="border" />}
    { isSuccess && <Alert variant="success" dismissible onClose={handleOnClose}>{removeUserAccessMessage}</Alert>}
    { error && <Alert variant="danger" dismissible onClose={handleOnClose}>{errorMessage}</Alert>}
    { getAllRolesData.loading && <Spinner animation="border" />}
    { getAllRolesData.error && <Alert variant="danger" dismissible >{getAllRolesData.error}</Alert>}
    <Card className="card border-dark mb-3">
        <Container className="card-header">
            Remove User Access
        </Container>
        <Container className="card-body">
            <Form validated={validated} onSubmit={handleApproveShow}>
                <Row>
                <Form.Group as={Row}>
                    <Col sm={4}><Form.Label>Select AWS Role:</Form.Label></Col>
                    <Col sm={6}>
                        <Form.Select 
                            aria-label="Default select example"
                            id="roleArn"
                            name="role_arn"
                            value={formData.role_arn}
                            onChange={handleChange}
                            required
                        >
                        <option label='Select AWS Role ...'></option>
                        {   !getAllRolesData.error && getAllRolesData.isloaded &&
                            getAllRolesData.roles.map( role => {
                                return <option key={role.aws_role_arn} value={role.aws_role_arn}>
                                    {role.aws_role_name}
                                </option>
                            })
                        }
                        </Form.Select>
                        </Col>
                        </Form.Group>
                    </Row>
                    <br></br>
                    { showUser && !showUserError &&
                    <Row>
                    <Form.Group>
                    <Col sm={12}>
                                <Form.Label>List of User who has access:({role.aws_role_name})</Form.Label>
                            </Col>
                        </Form.Group>
                        {emails.map((email, index) => (
                        <Form.Group as={Row} key={index} controlId={`formEmail${index}`} style={{ marginBottom: '10px' }}>
                            
                            <Col sm={6}>
                                <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => handleEmailChange(index, e)}
                                placeholder={`User Email ${index + 1}`}
                                required
                                />
                            </Col>
                            <Col sm={2}>
                                {emails.length >= 1 && (
                                <Button variant="danger" type="button" onClick={() => handleRemoveEmail(index)}>
                                    <FaMinus />
                                </Button>
                                )}

                            </Col>
                            <Col sm={2}>
                            </Col>
                        </Form.Group>
                        ))} 
                    </Row>}
                    { showUser && showUserError &&
                    <Row>
                        {/* <Form.Label>{ role !== undefined} && List of User who has access:({role.aws_role_name})</Form.Label> */}
                        <Container style={{color: 'red'}}>No User has Access to this Role</Container>
                    </Row>  
                    }
                    <br></br>
                    <Row>
                        <Col sm={4}>
                            <Button type="submit" variant='warning' disabled = {!showUser || showUserError || loading}>Remove User Access</Button>
                        </Col>
                        
                    </Row>
            </Form>
            <Modal show={showApprove} onHide={handleApproveClose}>
                            <Modal.Header closeButton>
                            <Modal.Title>Below user access will be removed.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th><strong>Role Name:</strong></th>
                                            <td>{role.aws_role_name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>User/s Whose Access Will be Removed:</strong></td>
                                            <td>
                                                {uniqueToEmails.map((email, index) => (
                                                    <div key={index}>
                                                        {email}
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            <Button variant="primary" className='mx-3' onClick={handleApproveClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" type="submit" onClick={handleSubmit}>
                                Update Role
                            </Button>
                        </Modal.Body>
                    </Modal>              
        </Container>
    </Card>
    </>
  )
}

export default RemoveUserAccess