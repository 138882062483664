import React from 'react'
import { Card, Container, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { addRole } from '../features/addRoleSlice';
import { resetAddRole, resetAddRoleError } from '../features/addRoleSlice';

import { FaPlus, FaMinus } from 'react-icons/fa';

function CreateRole() {

    const [validated, setValidated] = useState(false);
    const { instance, inProgress, accounts } = useMsal();
    const { loading, error, errorMessage, isSuccess, addRoleMessage} = useSelector((state) => state.addRole)
    const dispatch = useDispatch()
    const [emails, setEmails] = useState([''])
    const [emailError, setEmailError] = useState('');

    const [formData, setFormData] = useState(
        {
            aws_role_arn: "",
            aws_role_name: "",
            aws_account_number: "",
            aws_account_tag: "",
            session_timeout: "",
        }
      )
    function handleEmailChange(index, event) {
        const newEmail = event.target.value;
        const newEmails = emails.map((email, i) => (i === index ? newEmail : email));
        setEmails(newEmails);
        setEmailError('');
    }

    const handleAddEmail = () => {
        if (emails.includes('')) {
            setEmailError('Please fill in the existing email field before adding a new one.');
            return;
          }
          setEmails([...emails, '']);
      };

    const handleRemoveEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
        setEmailError('');
      };

    function handleOnClose() {
        dispatch(resetAddRole())
        dispatch(resetAddRoleError())
    }

    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        const emailSet = new Set(emails);
        if (emailSet.size !== emails.length) {
            setEmailError('Duplicate emails are not allowed.');
            return;
        }

        if (form.checkValidity() === false) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }

        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
            };

        if (inProgress === InteractionStatus.None && form.checkValidity()) {
           instance
               .acquireTokenSilent(accessTokenRequest)
               .then((accessTokenResponse) => {
                   let accessToken = accessTokenResponse.accessToken;
                   const data = {
                        'access_token': `Bearer ${accessToken}`,
                        'email': instance.getActiveAccount().username,
                        'aws_role_arn': formData.aws_role_arn,
                        'aws_role_name': formData.aws_role_name,
                        'aws_account_number': formData.aws_account_number,
                        'aws_account_tag': formData.aws_account_tag,
                        'admin_emails': emails,
                        'session_timeout': formData.session_timeout
                   }
                   dispatch(addRole(data))
                       .catch((error) => {
                           if (error instanceof InteractionRequiredAuthError) {
                               instance.acquireTokenRedirect(accessTokenRequest);
                           }
                       })
               })         
       }
    }
  return (
    <>
    <h4>Add New Role</h4>
    <hr></hr>
        {emailError && <Alert variant="danger" dismissible >{emailError}  </Alert>}
        {loading && <Spinner></Spinner> }
        { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnClose}>{errorMessage}</Alert>}
        { isSuccess && <Alert variant='success' dismissible className='ms-auto' onClose={handleOnClose}>{addRoleMessage}</Alert>}
        <Card className="card border-dark mb-3">
            <Container className="card-header">
                Add New Role
            </Container>
            <Container className="card-body">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>AWS Role ARN:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="AWS Role ARN"
                                value={formData.aws_role_arn}
                                onChange={handleChange}
                                name="aws_role_arn"
                            />
                            <Form.Control.Feedback type="invalid">Please Provide Account Tag Name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Role Name:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Role Name"
                                value={formData.aws_role_name}
                                onChange={handleChange}
                                name="aws_role_name"
                            />
                            <Form.Control.Feedback type="invalid">Please Provide a valid role Name</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>AWS Account Number:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="AWS Account Number"
                                value={formData.aws_account_number}
                                onChange={handleChange}
                                name="aws_account_number"
                            />
                            <Form.Control.Feedback type="invalid">Please Provide Account Number.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Account Tag:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="AWS Account Tag"
                                value={formData.aws_account_tag}
                                onChange={handleChange}
                                name="aws_account_tag"
                            />
                            <Form.Control.Feedback type="invalid">Please Provide AWS Account Tag.</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                    <Form.Group as={Col}>
                    <Form.Label>Sessions Timeout Value(in Hours):</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Admin Email/s:</Form.Label>
                    </Form.Group>
                    </Row>
                    <Row>
                    <Form.Group as={Row} >
                    <Col sm={6}>
                        <Form.Select 
                        aria-label="Default select example"
                        id="role"
                        name="session_timeout"
                        value={formData.session_timeout}
                        onChange={handleChange}
                        required
                        >
                            <option label='Select an Time Out Value ...'></option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>

                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Select a session timeout Value
                        </Form.Control.Feedback>
                        </Col>
                        
                        <Col sm={6}>
                            {emails.map((email, index) => (
                            <Form.Group as={Row} key={index} controlId={`formEmail${index}`} style={{ marginBottom: '10px' }}>
                            <Col sm={10}>
                                <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => handleEmailChange(index, e)}
                                placeholder={`Admin Email ${index + 1}`}
                                required
                                />
                            </Col>
                            <Col sm={1}>
                                {emails.length > 1 && (
                                <Button variant="danger" type="button" onClick={() => handleRemoveEmail(index)}>
                                    <FaMinus style={{ fontSize: '10px' }}/>
                                </Button>
                                )}
                                </Col>
                                <Col sm={1}>
                                { index === emails.length - 1 && <Button variant="success" type="button" onClick={handleAddEmail}>
                                <FaPlus style={{ fontSize: '10px' }}/>
                                </Button> }


                            </Col>
                        </Form.Group>
                        
                    ))} 
                    </Col>
                    </Form.Group>
                    </Row>                    
                    
                    <br></br>
                    <Button type="submit" disabled={loading}>Add Role</Button>
                </Form>
            </Container>
        </Card>
    </>
  )
}

export default CreateRole