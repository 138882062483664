import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { getAllRoles } from '../features/getAllRolesSlice';
import { resetGetAllRolesError } from '../features/getAllRolesSlice';
import { LinkContainer } from 'react-router-bootstrap';
import { Container, Row, Table, Pagination, Spinner, Alert, Button, Card, Form } from 'react-bootstrap';


function ListAllRoles() {
  const { instance, inProgress, accounts } = useMsal();
  const { loading, roles, error, errorMessage } = useSelector((state) => state.getAllRoles)
  const userData = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [query, setQuery] = useState('');
  const [filteredRoles, setFilteredRoles] = useState([])
  const [showFilteredRoles, setShowFilteredRoles] = useState(false)

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    const userRoles = roles.filter(role => role.aws_role_arn.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredRoles(userRoles);
    setShowFilteredRoles(true)
    // Use the filteredRoles array as needed
};


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = userData.loginUser.role === 'Super Admin' ? 12: 8;

  
  
  const handleClick = (number) => {
    setCurrentPage(number);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = showFilteredRoles ? filteredRoles.slice(indexOfFirstItem, indexOfLastItem) : roles.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = showFilteredRoles ? Math.ceil(filteredRoles.length / itemsPerPage) : Math.ceil(roles.length / itemsPerPage);


  const table_body = currentItems.map( role => {
    return (
                <tr key={role.aws_role_arn}>
                <td className="col-4">{role.aws_role_name}</td>
                <td className="col-2">{role.aws_account_number}</td>
                <td className="col-2">{role.aws_account_tag}</td>
                {userData.loginUser.role === 'Super Admin' ?
                <td className="col-4">
                  <LinkContainer to={{ pathname: "/admin/role", search: "arn=" + role.aws_role_arn }} key={role.aws_role_arn}>
                    <Button variant='primary'>Manage Role</Button>
                  </LinkContainer>
                </td> :
                <>
                <td>
                  {role.admin_emails.length === 0 && 'None'}
                    {role.admin_emails.map((email, index) => (
                  <div key={index}>
                      {email}
                  </div>
                  ))}
                </td>
                <td>
                {role.user_emails.length === 0 && 'None'}
                    {role.user_emails.map((email, index) => (
                  <div key={index}>
                      {email}
                  </div>
                  ))}
                </td>
                </>
                  }
            </tr>
      )}
)

  function handleOnClose() {
    dispatch(resetGetAllRolesError())
  }


  useEffect(() => {
    const accessTokenRequest = {
    scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
    account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
    instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        const data = {
            'access_token': `Bearer ${accessToken}`,
            'email': instance.getActiveAccount().username,
        }
        dispatch(getAllRoles(data))
        })
        .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
        }
        });
    }
  }, [instance, accounts, inProgress, dispatch]);

  let items = [];

  if (totalPages <= 7) {
  for (let i = 1; i <= totalPages; i++) {
    items.push(
      <Pagination.Item key={i} active={i === currentPage} onClick={() => handleClick(i)}>
        {i}
      </Pagination.Item>
    );
  }
  } else {
    items.push(
      <Pagination.Item key={1} active={1 === currentPage} onClick={() => handleClick(1)}>
        1
      </Pagination.Item>
    );

    if (currentPage > 4) {
      items.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handleClick(i)}>
          {i}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages - 3) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    items.push(
      <Pagination.Item key={totalPages} active={totalPages === currentPage} onClick={() => handleClick(totalPages)}>
        {totalPages}
      </Pagination.Item>
    );
  }



  return (
    
    <>
    {userData.loginUser.role === 'Super Admin' ?
    <h4>Manage Existing Roles</h4> : <h4>My Admin Roles</h4>}
    <hr></hr>

    {loading && <Spinner></Spinner> }
    { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnClose}>{errorMessage}</Alert>}
      
    <Card className="card border-dark mb-3">
            <Container className="card-header">
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Search Role</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Search By Role Name" 
                            value={query}
                            onChange={handleInputChange}
                            />
                    </Form.Group>
                </Form>
            </Container>
    </Card>
    { userData.loginUser.role === 'Super Admin' ? 
    <Container>
        
      <Row>
        <Table className='table table-hover'>
            <thead>
                <tr className='table-dark'>
                <th>Role Name</th>
                <th>Account No</th>
                <th>Account Tag</th>
                <th>Manage Role</th>
                </tr>
            </thead>
            <tbody>
                {table_body}
            </tbody>

        </Table>
      </Row>
      <Row>
        <Pagination className='pagination'>
        <Pagination.First onClick={() => handleClick(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1} />
        {items}
        <Pagination.Next onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handleClick(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </Row>    
      </Container> : 
      <Container>
        <Row>
        <Table className='table table-hover'>
            <thead>
                <tr className='table-dark'>
                <th>Role Name</th>
                <th>Account No</th>
                <th>Account Tag</th>
                <th>Admins</th>
                <th>Users</th>
                </tr>
            </thead>
            <tbody>
                {table_body}
            </tbody>

        </Table>
      </Row>
      <Row>
        <Pagination className='pagination'>
        <Pagination.First onClick={() => handleClick(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1} />
        {items}
        <Pagination.Next onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handleClick(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </Row> 
      </Container>}
      </>
    
  )
}

export default ListAllRoles