import React from 'react'
import { Card, Container, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { addUser } from '../features/addUserSlice';

import { resetAddUser, resetAddUserError } from '../features/addUserSlice';



export default function AddUser() {

    const [validated, setValidated] = useState(false);
    const { instance, inProgress, accounts } = useMsal();
    const { loading, isSuccess, error, errorMessage, addUserMessage } = useSelector((state) => state.addUser)
    const dispatch = useDispatch()

    const [formData, setFormData] = useState(
        {
            user_email: "",
            user_role: "",
        }
      )

    function handleChange(event) {
        console.log(formData)
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;


        if (form.checkValidity() === false) {
            console.log("inside if")
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }

        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
            };

        if (inProgress === InteractionStatus.None && form.checkValidity()) {
           instance
               .acquireTokenSilent(accessTokenRequest)
               .then((accessTokenResponse) => {
                   let accessToken = accessTokenResponse.accessToken;
                   const data = {
                       'access_token': `Bearer ${accessToken}`,
                       'email': instance.getActiveAccount().username,
                       'user_email': formData.user_email,
                       'user_role': formData.user_role
                   }
                   dispatch(addUser(data))
                       .catch((error) => {
                           if (error instanceof InteractionRequiredAuthError) {
                               instance.acquireTokenRedirect(accessTokenRequest);
                           }
                       })
               })         
       }
    }


  return (
    <>
    <h4>Add User</h4>
    <hr></hr>
    {loading && <Spinner animation="border" />}
    { isSuccess && <Alert variant="success" dismissible onClose={() => dispatch(resetAddUser())} >{addUserMessage}</Alert>}
    { error && <Alert variant="danger" dismissible onClose={() => dispatch(resetAddUserError())}  >{errorMessage}</Alert>}
    <Card className="card border-dark mb-3">
        <Container className="card-header">
            Add New User
        </Container>
        <Container className="card-body">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="useremailvalidator">
                    <Form.Label>Email Id:</Form.Label>
                    <Form.Control
                            required
                            type="email"
                            placeholder="Email ID"
                            value={formData.user_email}
                            onChange={handleChange}
                            name="user_email"
                        />
                    <Form.Control.Feedback type="invalid">Please Provide User Email ID.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="user.ControlInput1">
                    <Form.Label>Select User Role:</Form.Label>
                    <Form.Select 
                        aria-label="Default select example"
                        id="role"
                        name="user_role"
                        value={formData.user_role}
                        onChange={handleChange}
                        required
                    >
                    <option label='Select an Role ...'></option>
                    <option value='User'>User</option>
                    <option value='Admin'>Admin</option>
                    <option value='Super Admin'>Super Admin</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Select an a Role which user needs access to
                    </Form.Control.Feedback>
                </Form.Group>
                </Row>
                <Button type="submit" disabled={loading} >Add User</Button>
            </Form>
        </Container>
    </Card>
    </>
  )
}
