import React from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Card, Form, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'
import { useMsal } from '@azure/msal-react'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";

import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../features/updateUserSlice'
import { resetUpdateUser, resetUpdateUserError } from '../features/updateUserSlice'

function UpdateUser() {
    
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user_email = queryParams.get('user_email')
    const user_role = queryParams.get('user_role')

    const [validated, setValidated] = useState(false);
    const { instance, inProgress, accounts } = useMsal();

    const { loading, isSuccess, error, errorMessage, updateUserMessage } = useSelector((state) => state.updateUser)

    const dispatch = useDispatch()

    const [formData, setFormData] = useState(
        {
            user_email: user_email,
            user_role: user_role,
        }
      )

    function handleChange(event) {
        console.log(formData)
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    function handleOnClose() {
        dispatch(resetUpdateUser())
        dispatch(resetUpdateUserError())
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;


        if (form.checkValidity() === false) {
            console.log("inside if")
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }

        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
            };

        if (inProgress === InteractionStatus.None && form.checkValidity()) {
           instance
               .acquireTokenSilent(accessTokenRequest)
               .then((accessTokenResponse) => {
                   let accessToken = accessTokenResponse.accessToken;
                   const data = {
                       'access_token': `Bearer ${accessToken}`,
                       'email': instance.getActiveAccount().username,
                       'user_email': formData.user_email,
                       'user_role': formData.user_role
                   }
                   dispatch(updateUser(data))
                       .catch((error) => {
                           if (error instanceof InteractionRequiredAuthError) {
                               instance.acquireTokenRedirect(accessTokenRequest);
                           }
                       })
               })         
       }
    }





  return (
    <>
    <h4>User Role Update</h4>
    <hr></hr>
    {loading && <Spinner animation="border" role="status"/>}
    {isSuccess && <Alert variant='success' dismissible onClick={handleOnClose}>{updateUserMessage}</Alert>}
    {error && <Alert variant='danger' dismissible onClick={handleOnClose}>{errorMessage} </Alert>}
    <Card className="card border-dark mb-3">
        <Container className="card-header">
            Add New User
        </Container>
        <Container className="card-body">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="useremailvalidator">
                    <Form.Label>Email Id:</Form.Label>
                    <Form.Control
                            required
                            type="email"
                            placeholder="Email ID"
                            value={formData.user_email}
                            onChange={handleChange}
                            name="user_email"
                            disabled
                        />
                    <Form.Control.Feedback type="invalid">Please Provide User Email ID.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="user.ControlInput1">
                    <Form.Label>Select User Role:</Form.Label>
                    <Form.Select 
                        aria-label="Default select example"
                        id="role"
                        name="user_role"
                        value={formData.user_role}
                        onChange={handleChange}
                        required
                    >
                    <option label='Select an Role ...'></option>
                    <option value='User'>User</option>
                    <option value='Admin'>Admin</option>
                    <option value='Super Admin'>Super Admin</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Select an a Role which user needs access to
                    </Form.Control.Feedback>
                </Form.Group>
                </Row>
                <Button type="submit" >Update User Role</Button>
            </Form>
        </Container>
        </Card>
    </>
  )
}

export default UpdateUser